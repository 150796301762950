import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { Delete, Trash, UploadCloud, X } from "react-feather";
import imageCompression from "browser-image-compression";
import { v4 } from "uuid";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const UploadHelper = (props) => {
  const [uploadError, setUploadError] = useState("");
  const [disableDropzone, setDisableDropzone] = useState(false);
  const [uploadStatus, setUploadStatus] = useState("");
  // let abortController;
  var uploadRequest = null;
  const uploadToCloud = async (compressedFile) => {
    if (compressedFile == null) return;
    setUploadStatus("Start");

    try {
      const user = firebase.auth().currentUser;
      if (!user) {
        throw new Error("User not authenticated");
      }
      const token = await user.getIdToken();
      const presigned =
        "https://bestmate.us/aws/generate-presigned-work-url?" +
        new URLSearchParams({
          filename: `${v4()}-${compressedFile.name}`,
          filetype: compressedFile.type,
        });
      const response = await fetch(presigned, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to get presigned URL");
      }
      const data = await response.json();
      const presignedUrl = data.url;
      // Upload file using presigned URL
      const uploadResponse = await fetch(presignedUrl, {
        method: "PUT",
        body: compressedFile,
        headers: {
          "Content-Type": compressedFile.type,
        },
      });

      if (!uploadResponse.ok) {
        throw new Error("Upload failed");
      }
      const finalUrl = presignedUrl.split("?")[0];
      const key = presignedUrl.split("?")[0].split(".com/")[1];

      if (props.type === "hero") {
        props.setWebsiteData((old) => {
          return { ...old, hero_image: finalUrl };
        });
      } else if (props.type === "about") {
        props.setWebsiteData((old) => {
          let oldabout =
            Object.keys(old).includes("about_us") &&
            Object.keys(old.about_us).length !== 0
              ? old.about_us
              : {};
          return {
            ...old,
            about_us: { ...oldabout, image: finalUrl },
          };
        });
      } else if (props.type === "team") {
        props.setWebsiteData((old) => ({
          ...old,
          image: finalUrl,
        }));
      } else if (props.type === "image") {
        props.setWebsiteData(() => ({
          image: finalUrl,
        }));
      }
      setUploadStatus("");
    } catch (err) {
      console.log(err);
      setUploadStatus("");
      setUploadError("Something went wrong!");
    }
  };

  const handleCancel = () => {
    console.log(uploadRequest);
    if (uploadRequest) {
      uploadRequest.abort();
      setUploadStatus("");
      setUploadError("Something went wrong!");
    }
  };
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    disabled: uploadStatus ? true : false,
    accept: { "image/*": [] },
    // maxFiles: 3 - imageData.length,
    maxFiles: 1,
    onDrop: async (acceptedFiles, error) => {
      console.log(error, acceptedFiles);
      if (error.length !== 0) {
        setUploadError(error[0].errors[0].message);
      } else {
        setUploadError("");
        try {
          acceptedFiles.map(async (elem) => {
            // let elem = acceptedFiles[0];
            if (elem.type.includes("image")) {
              const compressedFile = await imageCompression(elem, {
                maxSizeMB: 10,
                maxWidthOrHeight: 2000,
              });
              if (elem.size > 10485760) {
                setUploadError("Select File Size upto 10 Mb");
                return;
              }
              setUploadStatus("Start");
              return uploadToCloud(compressedFile);
              // console.log(elem, compressedFile);
            } else {
              if (elem.size > 10485760) {
                setUploadError("Select File Size upto 10 Mb");
                return;
              }
              setUploadStatus("Start");
              return uploadToCloud(elem);
            }
          });
        } catch (err) {
          console.log(err);
          setUploadStatus("");
          setUploadError("Something went wrong!");
        }
      }
    },
  });
  return (
    <section>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div
          style={{
            width: 250,
            height: 150,
            backgroundColor: "#f7f7f7",
            borderRadius: 5,
            border: "1px dashed #c9c8c7",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: 5,
            cursor: "pointer",
          }}
        >
          {uploadStatus ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
              onClick={() => handleCancel()}
            >
              <Spinner
                animation="border"
                // variant="dark"
                size="md"
                style={{ color: "#407FEA" }}
              />
              <div style={{ marginTop: 20 }}>Uploading</div>
            </div>
          ) : (
            <>
              <UploadCloud
                size={35}
                strokeWidth={2}
                stroke={"#407FEA"}
                style={{ marginRight: 15 }}
              />
              <div
                style={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                <span>Add Image</span>
              </div>
              <div>Maximum 10MB in size.</div>
            </>
          )}
          {uploadError ? (
            <div
              style={{
                color: "red",
                fontSize: 11,
                marginTop: 10,
              }}
            >
              {uploadError}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </section>
  );
};
export default UploadHelper;
