/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import axios from "axios";
import {
  Container,
  Card,
  Col,
  Row,
  Button,
  Modal,
  Spinner,
  Image,
  Breadcrumb,
  Form,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCheckSquare,
  faCoffee,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import online_payment from "../../assets/img/icons/online.png";
import ic_apple from "../../assets/img/icons/ic_apple.png";
import ic_google from "../../assets/img/icons/ic_google.png";
import ic_amex from "../../assets/img/icons/ic_amex_100.png";
import ic_diners from "../../assets/img/icons/ic_diners_100.png";
import ic_discover from "../../assets/img/icons/ic_discover_100.png";
import ic_jcb from "../../assets/img/icons/ic_jcb_100.png";
import visa from "../../assets/img/icons/visa.png";
import venmo from "../../assets/img/icons/venmo.png";
import discover from "../../assets/img/icons/discover.png";
import amex from "../../assets/img/icons/amex.png";
import check90 from "../../assets/img/icons/check90.png";
import master from "../../assets/img/icons/master.png";
import stripe from "../../assets/img/icons/ds.png";
import square from "../../assets/img/icons/square.png";
import paypal from "../../assets/img/icons/paypal.png";
import ach from "../../assets/img/icons/ach.png";
import gpay from "../../assets/img/icons/gpay.png";
import apay from "../../assets/img/icons/apay.png";
import apple from "../../assets/img/icons/apple2.png";
import google from "../../assets/img/icons/google2.png";
import card from "../../assets/img/icons/card.png";
import useSidebar from "../../hooks/useSidebar";
import NotyfContext from "../../contexts/NotyfContext";
import { useNavigate } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { Loader, X } from "react-feather";
import ReactSwitch from "react-switch";
import useAuth from "../../hooks/useAuth";
import { SQUARE_PRODUCTION } from "../../constants";
const OnlinePayments = () => {
  const [token, settoken] = useState("");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [openModals, setOpenModals] = useState(false);
  const [openModalsSquare, setOpenModalsSquare] = useState(false);
  const [openModalsPaypal, setOpenModalsPaypal] = useState(false);
  const [url, setUrl] = useState("");
  const [show, setShow] = useState(false);
  const { isOpen } = useSidebar();
  const notyf = useContext(NotyfContext);
  const [btnLoader, setBtnLoader] = useState(false);
  const [squareBtnLoader, setSquareBtnLoader] = useState(false);
  const [btnLoaderPaypal, setBtnLoaderPaypal] = useState(false);
  const [disconnectbtnLoader, setDisconnectBtnLoader] = useState(false);
  const [disconnectbtnLoaderPaypal, setDisconnectBtnLoaderPaypal] =
    useState(false);
  const [disconnectbtnLoaderSquare, setDisconnectBtnLoaderSquare] =
    useState(false);
  const { bdata } = useAuth();

  const navigate = useNavigate();
  let usercurrency = localStorage.getItem("currency") || "USD";
  const fetchdata = async () => {
    var user = firebase.auth().currentUser;
    if (user) {
      let tokenss = await user.getIdToken();
      // fetch("https://bestmate.us/api/business/stripe/check-user", {
      fetch("https://bestmate.us/api/onboard/payments-combined/check-user", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
      })
        .then((res) => res.json())
        .then(async (result) => {
          // console.log("POPOPOPOPO");
          // let res = await fetch("http://localhost:4242/paypal/check-user", {
          //   method: "GET",
          //   headers: {
          //     Accept: "application/json",
          //     "Content-Type": "application/json",
          //     Authorization: "Bearer " + tokenss,
          //   },
          // });
          // console.log(res);
          // let data = await res.json();
          // console.log({ stripe: res, paypal: result, paymentMethords: "" });
          // setData({ stripe: result, paypal: data, paymentMethords: "" });
          setData(result);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };
  const DisconnectAccount = async () => {
    setDisconnectBtnLoader(true);
    var user = firebase.auth().currentUser;

    if (user) {
      let tokenss = await user.getIdToken();
      fetch("https://bestmate.us/api/business/stripe/disconnect", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
      })
        .then((result) => {
          setData({});
          setLoading(true);
          fetchdata();
          setOpenModals(!openModals);
          setDisconnectBtnLoader(false);
          notyf.open({
            type: isOpen ? "home" : "full",
            message: "Acccount removed successfully",
            ripple: "true",
            dismissible: "true",
          });
        })
        .catch((err) => {
          setDisconnectBtnLoader(false);
        });
    }
  };
  const DisconnectAccountSquare = async () => {
    setDisconnectBtnLoaderSquare(true);
    var user = firebase.auth().currentUser;

    if (user) {
      let tokenss = await user.getIdToken();
      fetch("https://bestmate.us/api/onboard/square/disconnect", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
        body: SQUARE_PRODUCTION
          ? JSON.stringify({})
          : JSON.stringify({ sandbox: "true" }),
      })
        .then((result) => {
          setData({});
          setLoading(true);
          fetchdata();
          setOpenModalsSquare(!openModalsSquare);
          setDisconnectBtnLoaderSquare(false);
          notyf.open({
            type: isOpen ? "home" : "full",
            message: "Acccount removed successfully",
            ripple: "true",
            dismissible: "true",
          });
        })
        .catch((err) => {
          setDisconnectBtnLoaderSquare(false);
        });
    }
  };
  const DisconnectAccountPaypal = async () => {
    setDisconnectBtnLoaderPaypal(true);
    var user = firebase.auth().currentUser;

    if (user) {
      let tokenss = await user.getIdToken();
      fetch("https://bestmate.us/api/onboard/paypal-disconnect", {
        // fetch("https://bestmate.us/api/business/stripe/disconnect", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
      })
        .then((result) => {
          setData({});
          setLoading(true);
          fetchdata();
          setOpenModalsPaypal(!openModalsPaypal);
          setDisconnectBtnLoaderPaypal(false);
          notyf.open({
            type: isOpen ? "home" : "full",
            message: "Acccount removed successfully",
            ripple: "true",
            dismissible: "true",
          });
        })
        .catch((err) => {
          setDisconnectBtnLoaderPaypal(false);
        });
    }
  };
  async function getSrc() {
    setBtnLoader(true);
    var user = firebase.auth().currentUser;

    if (user) {
      let tokenss = await user.getIdToken();
      const host = window.location.host;
      const baseUrl = host.includes("app.gobestmate.com")
        ? "gobestmate.com"
        : "bestmate.us";
      fetch(`https://${baseUrl}/onboard/oauth-link-return`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.url) {
            // window.location = data.url;
            window.open(data.url, "_blank");
            setBtnLoader(false);
          }
        })
        .catch((err) => {
          setBtnLoader(false);
        });
    }
  }
  async function getSquareSrc() {
    setSquareBtnLoader(true);
    var user = firebase.auth().currentUser;

    if (user) {
      let tokenss = await user.getIdToken();
      const host = window.location.host;
      const baseUrl = host.includes("app.gobestmate.com")
        ? "gobestmate.com"
        : "bestmate.us";
      fetch(
        `https://${baseUrl}/onboard/square/oauth-link-return${
          SQUARE_PRODUCTION ? "" : "?sandbox=true"
        }`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + tokenss,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.url) {
            // window.location = data.url;
            window.open(data.url, "_blank");
            setSquareBtnLoader(false);
          }
        })
        .catch((err) => {
          setSquareBtnLoader(false);
        });
    }
  }
  async function getSrcPaypal() {
    setBtnLoaderPaypal(true);
    var user = firebase.auth().currentUser;

    if (user) {
      let tokenss = await user.getIdToken();
      // fetch("http://localhost:4242/get-oauth-paypal-link", {
      fetch("https://bestmate.us/api/onboard/oauth-link-paypal", {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.url) {
            // window.location = data.url;
            window.open(data.url, "_blank");
            setBtnLoaderPaypal(false);
          }
        })
        .catch((err) => {
          setBtnLoaderPaypal(false);
        });
    }
  }
  useEffect(async () => {
    mixpanel.track(`On Setup Payments (Web)`);
    var user = firebase.auth().currentUser;
    setData({});
    // firebase.auth().onAuthStateChanged(async (user) => {
    if (user) {
      let tokenss = await user.getIdToken();
      settoken(tokenss);
      fetchdata();
    }
    // });
  }, []);
  return (
    <>
      <Helmet title="Online Payments" />
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100%" }}
        >
          <Spinner animation="border" variant="dark" className="me-2" />
        </div>
      ) : (
        <React.Fragment>
          <Container fluid className="p-0">
            <Card
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                // backgroundColor: "white",
                padding: "0rem 1.5rem 2rem 1.5rem",
              }}
              className="pt-2 pb-2 border-bottom "
            >
              <div>
                <h2
                  className="mt-3 lead"
                  style={{
                    fontSize: "24px",
                    // , fontWeight: 100
                  }}
                >
                  Online Payments
                </h2>
                <Breadcrumb className="mb-0 lead" style={{ fontSize: "13px" }}>
                  <Breadcrumb.Item
                    active
                    onClick={() => navigate("/dashboard")}
                    style={{ cursor: "pointer" }}
                  >
                    Home
                  </Breadcrumb.Item>
                  {/* <Breadcrumb.Item active>Invoice</Breadcrumb.Item> */}
                  <Breadcrumb.Item active className="font-weight-bold">
                    Online Payments
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div></div>
            </Card>
            <div style={{ padding: "0rem 1.5rem 2rem 1.5rem" }}>
              {/* <h1 className="h3 mb-3">Online Payments</h1> */}
              {/* <Card className="mb-2 border rounded-0">
                <Card.Header>
                  <Row>
                    <Col md={8}>
                      <p
                        style={{
                          fontSize: "1.7rem",
                          color: "black",
                          margin: 0,
                        }}
                      >
                        Grow your Business with Bestmate
                      </p>
                      <p style={{ fontSize: 15, margin: 0 }}>
                        Start Accepting online card payments on Invoice by
                        setting up a Payment Account
                      </p>

                      <p
                        className="h4"
                        style={{
                          marginLeft: 14,
                          marginTop: 25,
                          fontWeight: "bold",
                        }}
                      >
                        Card Payments
                      </p>
                      <div style={{ marginTop: 10, marginLeft: 14 }}>
                        <Image
                          style={{ height: 40, width: 40 }}
                          src={ic_visa}
                        />
                        <Image
                          style={{ height: 40, width: 40, marginLeft: "5px" }}
                          src={ic_mastercard}
                        />
                        <Image
                          style={{ height: 40, width: 40, marginLeft: "5px" }}
                          src={ic_amex}
                        />
                        <Image
                          style={{ height: 40, width: 40, marginLeft: "5px" }}
                          src={ic_discover}
                        />
                        <Image
                          style={{ height: 40, width: 40, marginLeft: "5px" }}
                          src={ic_diners}
                        />
                        <Image
                          style={{ height: 40, width: 40, marginLeft: "5px" }}
                          src={ic_jcb}
                        />
                      </div>
                      <p
                        className="h4"
                        style={{
                          marginLeft: 14,
                          marginTop: 25,
                          fontWeight: "bold",
                        }}
                      >
                        Wallets
                      </p>
                      <div style={{ marginTop: 0, marginLeft: 14 }}>
                        <Image style={{ height: 40 }} src={ic_apple} />
                        <Image
                          style={{ height: 53, marginLeft: 10 }}
                          src={ic_google}
                        />
                      </div>
                      {data.chargesEnable ? (
                        <Button
                          style={{
                            marginTop: 25,
                            borderRadius: 3,
                            marginLeft: 14,
                            padding: 10,
                            fontWeight: "bold",
                          }}
                          onClick={() => setOpenModals(!openModals)}
                        >
                          Disconnect Payment Account
                        </Button>
                      ) : (
                        <Button
                          style={{
                            marginTop: 25,
                            borderRadius: 3,
                            marginLeft: 14,
                            padding: 10,
                            fontWeight: "bold",
                          }}
                          onClick={() => getSrc()}
                        >
                          Connect Payment Account
                        </Button>
                      )}
                    </Col>
                    <Col md={4}>
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          height: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Image
                          style={{
                            maxWidth: "100%",
                            width: 500,
                            marginLeft: "5px",
                          }}
                          src={online_payment}
                        />
                      </div>
                    </Col>
                  </Row>
                </Card.Header>
              </Card> */}
              {/* <Row>
                <Col md={4}>
                  <div
                    style={{
                      width: "100%",
                      // backgroundColor: "pink",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      // marginTop: 20,
                    }}
                  >
                    <div
                      style={{
                        fontSize: 35,
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Bestmate Payments
                    </div>
                    <div
                      style={{
                        width: "100%",
                        maxWidth: 320,
                        paddingRight: 10,
                        paddingLeft: 10,
                        backgroundColor: "white",
                        marginTop: 25,
                        marginBottom: 15,
                        display: "flex",
                        height: 70,
                        boxShadow: "0px 0.5px 4px grey",
                        flexDirection: "row",
                        borderRadius: 20,
                        alignItems: "center",
                      }}
                      // className="border"
                    >
                      <Image style={{ width: 40, margin: 5 }} src={visa} />
                      <Image
                        style={{ width: 40, margin: 5, marginRight: 15 }}
                        src={master}
                      />
                      <div>
                        <p
                          style={{
                            margin: 0,
                            fontSize: 12,
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          Credit Card or Debit Card
                        </p>
                        <p style={{ margin: 0, fontSize: 12 }}>Accepted</p>
                      </div>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        maxWidth: 320,
                        paddingRight: 10,
                        paddingLeft: 10,
                        backgroundColor: "white",
                        marginTop: 15,
                        // height: 63,
                        height: 70,
                        boxShadow: "0px 0.5px 4px grey",
                        marginBottom: 15,
                        display: "flex",
                        flexDirection: "row",
                        borderRadius: 20,
                        alignItems: "center",
                      }}
                      // className="border"
                    >
                      <Image style={{ width: 40, margin: 35 }} src={ach} />
                      <div>
                        <p
                          style={{
                            margin: 0,
                            fontSize: 12,
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          Net Banking
                        </p>
                        <p style={{ margin: 0, fontSize: 12 }}>Accepted</p>
                      </div>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        maxWidth: 320,
                        paddingRight: 10,
                        paddingLeft: 10,
                        backgroundColor: "white",
                        marginTop: 15,
                        // height: 63,
                        height: 70,
                        boxShadow: "0px 0.5px 4px grey",
                        marginBottom: 15,
                        display: "flex",
                        flexDirection: "row",
                        borderRadius: 20,
                        alignItems: "center",
                      }}
                      // className=" border"
                    >
                      <Image style={{ width: 80, margin: 15 }} src={apay} />
                      <div>
                        <p
                          style={{
                            margin: 0,
                            fontSize: 12,
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          Apple Pay
                        </p>
                        <p style={{ margin: 0, fontSize: 12 }}>Accepted</p>
                      </div>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        maxWidth: 320,
                        paddingRight: 10,
                        paddingLeft: 10,
                        // height: 63,
                        height: 70,
                        boxShadow: "0px 0.5px 4px grey",
                        backgroundColor: "white",
                        marginTop: 15,
                        marginBottom: 15,
                        display: "flex",
                        flexDirection: "row",
                        borderRadius: 20,
                        alignItems: "center",
                      }}
                      // className="border"
                    >
                      <Image style={{ width: 80, margin: 15 }} src={gpay} />

                      <div>
                        <p
                          style={{
                            margin: 0,
                            fontSize: 12,
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          Google Pay
                        </p>
                        <p style={{ margin: 0, fontSize: 12 }}>Accepted</p>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={8}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "85%",
                        // height: 230,
                        backgroundColor: "white",
                        // marginTop: 20,
                        marginBottom: 10,
                        boxShadow: "1px 2px 6px grey",
                        textAlign: "left",
                        padding: 30,
                        // marginTop: 30,
                        borderRadius: 30,
                      }}
                      className="pt-3 pb-4 border"
                    >
                      <p
                        style={{
                          // color: "#2E6ACF",
                          fontWeight: "bold",
                          // fontSize: 20,
                          fontSize: 15,
                          marginBottom: 10,
                        }}
                      >
                        What are you waiting for
                      </p>
                      <p
                        style={{
                          color: "black",
                          fontSize: 30,
                          fontWeight: "bold",
                          lineHeight: "120%",
                        }}
                      >
                        Simple, Fast and Secure <br /> way to accept Payments
                      </p>
                      <Row>
                        <Col md={6}>
                          <div
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              padding: 5,
                              border: 0,
                              display: "flex",
                              flexDirection: "row",
                              // justifyContent: "center",
                              alignItems: "center",
                              // borderBottomWidth: 1,
                              // borderColor: "#d6d6d6",
                              // borderStyle: "dashed",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faCheckSquare}
                              color="#2E6ACF"
                              style={{ marginRight: 10 }}
                            />
                            <div style={{ lineHeight: "120%" }}>
                              Ultra Safe Payment Gateway
                            </div>
                          </div>

                          <div
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              padding: 5,
                              border: 0,
                              display: "flex",
                              flexDirection: "row",
                              // justifyContent: "center",
                              alignItems: "center",
                              // borderBottomWidth: 1,
                              // borderColor: "#d6d6d6",
                              // borderStyle: "dashed",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faCheckSquare}
                              color="#2E6ACF"
                              style={{ marginRight: 10 }}
                            />
                            <div style={{ lineHeight: "120%" }}>
                              Your mobile POS & collection tool
                            </div>
                          </div>
                          <div
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              padding: 5,
                              border: 0,
                              display: "flex",
                              flexDirection: "row",
                              // justifyContent: "center",
                              alignItems: "center",
                              // borderBottomWidth: 1,
                              // borderColor: "#d6d6d6",
                              // borderStyle: "dashed",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faCheckSquare}
                              color="#2E6ACF"
                              style={{ marginRight: 10 }}
                            />
                            <div style={{ lineHeight: "120%" }}>
                              Multi-currency
                            </div>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              padding: 5,
                              border: 0,
                              display: "flex",
                              flexDirection: "row",
                              // justifyContent: "center",
                              alignItems: "center",
                              // borderBottomWidth: 1,
                              // borderColor: "#d6d6d6",
                              // borderStyle: "dashed",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faCheckSquare}
                              color="#2E6ACF"
                              style={{ marginRight: 10 }}
                            />
                            <div style={{ lineHeight: "120%" }}>
                              Get paid faster anytime anywhere
                            </div>
                          </div>
                          <div
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              padding: 5,
                              border: 0,
                              display: "flex",
                              flexDirection: "row",
                              // justifyContent: "center",
                              alignItems: "center",
                              // borderBottomWidth: 1,
                              // borderColor: "#d6d6d6",
                              // borderStyle: "dashed",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faCheckSquare}
                              color="#2E6ACF"
                              style={{ marginRight: 10 }}
                            />
                            <div style={{ lineHeight: "120%" }}>
                              Any mode - Cards, Netbank, Wallet
                            </div>
                          </div>
                          <div
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              padding: 5,
                              border: 0,
                              display: "flex",
                              flexDirection: "row",
                              // justifyContent: "center",
                              alignItems: "center",
                              // borderBottomWidth: 1,
                              // borderColor: "#d6d6d6",
                              // borderStyle: "dashed",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faCheckSquare}
                              color="#2E6ACF"
                              style={{ marginRight: 10 }}
                            />
                            <div style={{ lineHeight: "120%" }}>Low Cost</div>
                          </div>
                        </Col>
                      </Row>
                      <div
                        style={{
                          fontWeight: "bold",
                          fontSize: 15,
                          marginBottom: 5,
                          marginTop: 10,
                          color: "black",
                        }}
                      >
                        Simple to Setup
                      </div>
                      <p>
                        Setup is so simple. We need your
                        <b> Company legal name and SSN </b>as part of US Legal
                        requirements to verify your identity and setup your
                        account. Bank details where we will send your collected
                        money.
                      </p>
                      <div
                        style={{
                          fontWeight: "bold",
                          fontSize: 15,
                          marginBottom: 5,
                          color: "black",
                        }}
                      >
                        Need help to setup?
                      </div>
                      <p style={{ marginBottom: 10 }}>
                        Just contact us and we will help set up a free 1-to-1
                        session with our staff. We will also assist and train
                        you to get started with using the app.
                      </p>
                      <button
                        style={{
                          border: "1px solid #2E6ACF",
                          // borderRadius: 5,
                          backgroundColor: "transparent",
                          // padding: 5,
                          color: "#2E6ACF",
                          borderRadius: 15,
                          // marginLeft: 14,
                          padding: 10,
                          fontWeight: "bold",
                          marginTop: 10,
                        }}
                        onClick={() => {
                          mixpanel.track(
                            `Book a free consultation (Online Payments Web)`
                          );
                          window.open(
                            "https://calendly.com/jim-bestmate/30min",
                            "_blank"
                          );
                        }}
                      >
                        Book a free consultation
                      </button>
                      {data.chargesEnabled ? (
                        <Button
                          style={{
                            // marginTop: 25,
                            borderRadius: 15,
                            marginLeft: 18,
                            padding: 10,
                            fontWeight: "bold",
                            // marginTop: 10,
                          }}
                          onClick={() => {
                            mixpanel.track(
                              `Discount Payment Account (Online Payments Web)`
                            );
                            setOpenModals(!openModals);
                          }}
                        >
                          Disconnect Payment Account
                        </Button>
                      ) : (
                        <>
                          {btnLoader ? (
                            <>
                              <Button
                                style={{
                                  // marginTop: 25,
                                  borderRadius: 15,
                                  marginLeft: 18,
                                  padding: 10,
                                  fontWeight: "bold",
                                  // marginTop: 10,
                                  width: 200,
                                }}
                                disabled
                              >
                                <Spinner
                                  animation="border"
                                  variant="light"
                                  size="sm"
                                />
                              </Button>
                            </>
                          ) : (
                            <Button
                              style={{
                                // marginTop: 25,
                                borderRadius: 15,
                                marginLeft: 18,
                                padding: 10,
                                fontWeight: "bold",
                                // marginTop: 10,
                              }}
                              onClick={() => {
                                mixpanel.track(
                                  `Connect Payment Account (Online Payments Web)`
                                );
                                getSrc();
                                // navigate("/iframe");
                              }}
                            >
                              Connect Payment Account
                            </Button>
                          )}
                        </>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <FontAwesomeIcon icon={faLock} size="xs" />
                      <div style={{ marginLeft: 7, fontSize: 11 }}>
                        Bestmate partners with Stripe to securely complete your
                        transactions.
                      </div>
                    </div>
                  </div>
                </Col>
              </Row> */}
              <div>
                {/* <Row>
                  <Col md={4}>
                    <div
                      style={{
                        width: "100%",
                        // backgroundColor: "pink",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        // marginTop: 20,
                      }}
                    >
                      <div
                        style={{
                          fontSize: 35,
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        Bestmate Payments
                      </div>
                      <div
                        style={{
                          width: "100%",
                          maxWidth: 320,
                          paddingRight: 10,
                          paddingLeft: 10,
                          backgroundColor: "white",
                          marginTop: 25,
                          marginBottom: 15,
                          display: "flex",
                          height: 70,
                          boxShadow: "0px 0.5px 4px grey",
                          flexDirection: "row",
                          borderRadius: 20,
                          alignItems: "center",
                        }}
                        // className="border"
                      >
                        <Image style={{ width: 40, margin: 5 }} src={visa} />
                        <Image
                          style={{ width: 40, margin: 5, marginRight: 15 }}
                          src={master}
                        />
                        <div>
                          <p
                            style={{
                              margin: 0,
                              fontSize: 12,
                              fontWeight: "bold",
                              color: "black",
                            }}
                          >
                            Credit Card or Debit Card
                          </p>
                          <p style={{ margin: 0, fontSize: 12 }}>Accepted</p>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          maxWidth: 320,
                          paddingRight: 10,
                          paddingLeft: 10,
                          backgroundColor: "white",
                          marginTop: 15,
                          // height: 63,
                          height: 70,
                          boxShadow: "0px 0.5px 4px grey",
                          marginBottom: 15,
                          display: "flex",
                          flexDirection: "row",
                          borderRadius: 20,
                          alignItems: "center",
                        }}
                        // className="border"
                      >
                        <Image style={{ width: 40, margin: 35 }} src={ach} />
                        <div>
                          <p
                            style={{
                              margin: 0,
                              fontSize: 12,
                              fontWeight: "bold",
                              color: "black",
                            }}
                          >
                            Net Banking
                          </p>
                          <p style={{ margin: 0, fontSize: 12 }}>Accepted</p>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          maxWidth: 320,
                          paddingRight: 10,
                          paddingLeft: 10,
                          backgroundColor: "white",
                          marginTop: 15,
                          // height: 63,
                          height: 70,
                          boxShadow: "0px 0.5px 4px grey",
                          marginBottom: 15,
                          display: "flex",
                          flexDirection: "row",
                          borderRadius: 20,
                          alignItems: "center",
                        }}
                        // className=" border"
                      >
                        <Image style={{ width: 80, margin: 15 }} src={apay} />
                        <div>
                          <p
                            style={{
                              margin: 0,
                              fontSize: 12,
                              fontWeight: "bold",
                              color: "black",
                            }}
                          >
                            Apple Pay
                          </p>
                          <p style={{ margin: 0, fontSize: 12 }}>Accepted</p>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          maxWidth: 320,
                          paddingRight: 10,
                          paddingLeft: 10,
                          // height: 63,
                          height: 70,
                          boxShadow: "0px 0.5px 4px grey",
                          backgroundColor: "white",
                          marginTop: 15,
                          marginBottom: 15,
                          display: "flex",
                          flexDirection: "row",
                          borderRadius: 20,
                          alignItems: "center",
                        }}
                        // className="border"
                      >
                        <Image style={{ width: 80, margin: 15 }} src={gpay} />

                        <div>
                          <p
                            style={{
                              margin: 0,
                              fontSize: 12,
                              fontWeight: "bold",
                              color: "black",
                            }}
                          >
                            Google Pay
                          </p>
                          <p style={{ margin: 0, fontSize: 12 }}>Accepted</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={8}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "85%",
                          // height: 230,
                          backgroundColor: "white",
                          // marginTop: 20,
                          marginBottom: 10,
                          boxShadow: "1px 2px 6px grey",
                          textAlign: "left",
                          padding: 30,
                          // marginTop: 30,
                          borderRadius: 30,
                        }}
                        className="pt-3 pb-4 border"
                      >
                        <p
                          style={{
                            // color: "#2E6ACF",
                            fontWeight: "bold",
                            // fontSize: 20,
                            fontSize: 15,
                            marginBottom: 10,
                          }}
                        >
                          What are you waiting for
                        </p>
                        <p
                          style={{
                            color: "black",
                            fontSize: 30,
                            fontWeight: "bold",
                            lineHeight: "120%",
                          }}
                        >
                          Simple, Fast and Secure <br /> way to accept Payments
                        </p>
                        <Row>
                          <Col md={6}>
                            <div
                              style={{
                                fontSize: 14,
                                fontWeight: "bold",
                                padding: 5,
                                border: 0,
                                display: "flex",
                                flexDirection: "row",
                                // justifyContent: "center",
                                alignItems: "center",
                                // borderBottomWidth: 1,
                                // borderColor: "#d6d6d6",
                                // borderStyle: "dashed",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faCheckSquare}
                                color="#2E6ACF"
                                style={{ marginRight: 10 }}
                              />
                              <div style={{ lineHeight: "120%" }}>
                                Ultra Safe Payment Gateway
                              </div>
                            </div>

                            <div
                              style={{
                                fontSize: 14,
                                fontWeight: "bold",
                                padding: 5,
                                border: 0,
                                display: "flex",
                                flexDirection: "row",
                                // justifyContent: "center",
                                alignItems: "center",
                                // borderBottomWidth: 1,
                                // borderColor: "#d6d6d6",
                                // borderStyle: "dashed",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faCheckSquare}
                                color="#2E6ACF"
                                style={{ marginRight: 10 }}
                              />
                              <div style={{ lineHeight: "120%" }}>
                                Your mobile POS & collection tool
                              </div>
                            </div>
                            <div
                              style={{
                                fontSize: 14,
                                fontWeight: "bold",
                                padding: 5,
                                border: 0,
                                display: "flex",
                                flexDirection: "row",
                                // justifyContent: "center",
                                alignItems: "center",
                                // borderBottomWidth: 1,
                                // borderColor: "#d6d6d6",
                                // borderStyle: "dashed",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faCheckSquare}
                                color="#2E6ACF"
                                style={{ marginRight: 10 }}
                              />
                              <div style={{ lineHeight: "120%" }}>
                                Multi-currency
                              </div>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div
                              style={{
                                fontSize: 14,
                                fontWeight: "bold",
                                padding: 5,
                                border: 0,
                                display: "flex",
                                flexDirection: "row",
                                // justifyContent: "center",
                                alignItems: "center",
                                // borderBottomWidth: 1,
                                // borderColor: "#d6d6d6",
                                // borderStyle: "dashed",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faCheckSquare}
                                color="#2E6ACF"
                                style={{ marginRight: 10 }}
                              />
                              <div style={{ lineHeight: "120%" }}>
                                Get paid faster anytime anywhere
                              </div>
                            </div>
                            <div
                              style={{
                                fontSize: 14,
                                fontWeight: "bold",
                                padding: 5,
                                border: 0,
                                display: "flex",
                                flexDirection: "row",
                                // justifyContent: "center",
                                alignItems: "center",
                                // borderBottomWidth: 1,
                                // borderColor: "#d6d6d6",
                                // borderStyle: "dashed",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faCheckSquare}
                                color="#2E6ACF"
                                style={{ marginRight: 10 }}
                              />
                              <div style={{ lineHeight: "120%" }}>
                                Any mode - Cards, Netbank, Wallet
                              </div>
                            </div>
                            <div
                              style={{
                                fontSize: 14,
                                fontWeight: "bold",
                                padding: 5,
                                border: 0,
                                display: "flex",
                                flexDirection: "row",
                                // justifyContent: "center",
                                alignItems: "center",
                                // borderBottomWidth: 1,
                                // borderColor: "#d6d6d6",
                                // borderStyle: "dashed",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faCheckSquare}
                                color="#2E6ACF"
                                style={{ marginRight: 10 }}
                              />
                              <div style={{ lineHeight: "120%" }}>Low Cost</div>
                            </div>
                          </Col>
                        </Row>
                        <div
                          style={{
                            fontWeight: "bold",
                            fontSize: 15,
                            marginBottom: 5,
                            marginTop: 10,
                            color: "black",
                          }}
                        >
                          Simple to Setup
                        </div>
                        <p>
                          Setup is so simple. We need your
                          <b> Company legal name and SSN </b>as part of US Legal
                          requirements to verify your identity and setup your
                          account. Bank details where we will send your
                          collected money.
                        </p>
                        <div
                          style={{
                            fontWeight: "bold",
                            fontSize: 15,
                            marginBottom: 5,
                            color: "black",
                          }}
                        >
                          Need help to setup?
                        </div>
                        <p style={{ marginBottom: 10 }}>
                          Just contact us and we will help set up a free 1-to-1
                          session with our staff. We will also assist and train
                          you to get started with using the app.
                        </p>
                        <button
                          style={{
                            border: "1px solid #2E6ACF",
                            // borderRadius: 5,
                            backgroundColor: "transparent",
                            // padding: 5,
                            color: "#2E6ACF",
                            borderRadius: 15,
                            // marginLeft: 14,
                            padding: 10,
                            fontWeight: "bold",
                            marginTop: 10,
                          }}
                          onClick={() => {
                            mixpanel.track(
                              `Book a free consultation (Online Payments Web)`
                            );
                            window.open(
                              "https://calendly.com/jim-bestmate/30min",
                              "_blank"
                            );
                          }}
                        >
                          Book a free consultation
                        </button>
                        {data.chargesEnabled ? (
                          <Button
                            style={{
                              // marginTop: 25,
                              borderRadius: 15,
                              marginLeft: 18,
                              padding: 10,
                              fontWeight: "bold",
                              // marginTop: 10,
                            }}
                            onClick={() => {
                              mixpanel.track(
                                `Discount Payment Account (Online Payments Web)`
                              );
                              setOpenModals(!openModals);
                            }}
                          >
                            Disconnect Payment Account
                          </Button>
                        ) : (
                          <>
                            {btnLoader ? (
                              <>
                                <Button
                                  style={{
                                    // marginTop: 25,
                                    borderRadius: 15,
                                    marginLeft: 18,
                                    padding: 10,
                                    fontWeight: "bold",
                                    // marginTop: 10,
                                    width: 200,
                                  }}
                                  disabled
                                >
                                  <Spinner
                                    animation="border"
                                    variant="light"
                                    size="sm"
                                  />
                                </Button>
                              </>
                            ) : (
                              <Button
                                style={{
                                  // marginTop: 25,
                                  borderRadius: 15,
                                  marginLeft: 18,
                                  padding: 10,
                                  fontWeight: "bold",
                                  // marginTop: 10,
                                }}
                                onClick={() => {
                                  mixpanel.track(
                                    `Connect Payment Account (Online Payments Web)`
                                  );
                                  getSrc();
                                  // navigate("/iframe");
                                }}
                              >
                                Connect Payment Account
                              </Button>
                            )}
                          </>
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <FontAwesomeIcon icon={faLock} size="xs" />
                        <div style={{ marginLeft: 7, fontSize: 11 }}>
                          Bestmate partners with Stripe to securely complete
                          your transactions.
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row> */}
                <div
                  style={{
                    fontSize: 35,
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Bestmate Payments
                </div>
                {/* <div style={{ display: "flex", justifyContent: "center" }}>
                  <Row
                    style={{
                      maxWidth: 1100,
                      alignSelf: "center",
                      paddingRight: 10,
                      paddingLeft: 10,
                      backgroundColor: "white",
                      marginTop: 25,
                      marginBottom: 15,
                      display: "flex",

                      boxShadow: "0px 0.5px 4px grey",
                      flexDirection: "row",
                      borderRadius: 20,
                      alignItems: "center",
                      paddingTop: 10,
                      paddingBottom: 10,
                    }}
                  >
                    <Col md={2}>
                      <div
                        style={{
                          width: "100%",
                          // backgroundColor: "pink",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                          // marginTop: 20,
                        }}
                      >
                        <Image style={{ width: 100, margin: 5 }} src={stripe} />
                      </div>
                    </Col>
                    <Col md={4}>
                      <div
                        style={{
                          width: "100%",
                          // backgroundColor: "pink",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          // alignItems: "center",
                          // marginTop: 20,
                        }}
                      >
                        <p
                          style={{
                            fontSize: 18,
                            marginBottom: 5,
                            fontWeight: "bold",
                            color: "#3f80ea",
                          }}
                        >
                          Secure payment options
                        </p>
                        <p>
                          Connect or create an account with Stripe to accept
                          credit card payments from clients around the world.
                        </p>
                        <div
                          style={{
                            // fontSize: 14,
                            // fontWeight: "bold",
                            padding: 5,
                            border: 0,
                            display: "flex",
                            flexDirection: "row",
                            // justifyContent: "center",
                            alignItems: "center",
                            // borderBottomWidth: 1,
                            // borderColor: "#d6d6d6",
                            // borderStyle: "dashed",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faCheckSquare}
                            color="#2E6ACF"
                            style={{ marginRight: 10 }}
                          />
                          <div style={{ lineHeight: "120%" }}>
                            Ultra Safe Payment Gateway
                          </div>
                        </div>

                        <div
                          style={{
                            // fontSize: 14,
                            // fontWeight: "bold",
                            padding: 5,
                            border: 0,
                            display: "flex",
                            flexDirection: "row",
                            // justifyContent: "center",
                            alignItems: "center",
                            // borderBottomWidth: 1,
                            // borderColor: "#d6d6d6",
                            // borderStyle: "dashed",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faCheckSquare}
                            color="#2E6ACF"
                            style={{ marginRight: 10 }}
                          />
                          <div style={{ lineHeight: "120%" }}>
                            Your mobile POS & collection tool
                          </div>
                        </div>
                        <div
                          style={{
                            // fontSize: 14,
                            // fontWeight: "bold",
                            padding: 5,
                            border: 0,
                            display: "flex",
                            flexDirection: "row",
                            // justifyContent: "center",
                            alignItems: "center",
                            // borderBottomWidth: 1,
                            // borderColor: "#d6d6d6",
                            // borderStyle: "dashed",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faCheckSquare}
                            color="#2E6ACF"
                            style={{ marginRight: 10 }}
                          />
                          <div style={{ lineHeight: "120%" }}>
                            Multi-currency
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div style={{ textAlign: "center" }}>
                        <div
                          style={{
                            marginTop: 10,
                            fontSize: 12,
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          CREDIT CARDS
                        </div>
                        <div>
                          <Image style={{ width: 30, margin: 5 }} src={visa} />
                          <Image
                            style={{ width: 30, margin: 5 }}
                            src={master}
                          />
                          <Image style={{ width: 50, margin: 0 }} src={apay} />

                          <Image style={{ width: 50, margin: 0 }} src={gpay} />
                        </div>
                        <div
                          style={{
                            fontSize: 13,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          2.9% + $0.30
                          <span
                            style={{
                              marginLeft: 3,
                              fontSize: 10,
                              color: "grey",
                            }}
                          >
                            per transaction for most cards
                          </span>
                        </div>
                        <div
                          style={{
                            color: "#3f80ea",
                            cursor: "pointer",
                            fontSize: 12,
                          }}
                        >
                          View fee table
                        </div>
                        <div
                          style={{
                            marginTop: 10,
                            fontSize: 12,
                            fontWeight: "bold",
                            color: "black",
                            marginBottom: 5,
                          }}
                        >
                          ACH BANK TRANSFER
                        </div>
                        <div>
                          <Image style={{ width: 40, margin: 0 }} src={ach} />
                        </div>
                        <div
                          style={{
                            fontSize: 13,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          1%
                          <span
                            style={{
                              marginLeft: 3,
                              fontSize: 10,
                              color: "grey",
                            }}
                          >
                            per transaction
                          </span>
                        </div>

                      </div>
                    </Col>
                    <Col md={2}>
                      <div
                        style={{
                          width: "100%",
                          // backgroundColor: "pink",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                          // marginTop: 20,
                        }}
                      >
                        {data && data.stripe && data.stripe.chargesEnabled ? (
                          <Button
                            style={{
                              // marginTop: 25,
                              borderRadius: 15,
                              marginLeft: 18,
                              padding: 10,
                              fontWeight: "bold",
                              // marginTop: 10,
                            }}
                            variant="light"
                            onClick={() => {
                              mixpanel.track(
                                `Discount Payment Account (Online Payments Web)`
                              );
                              setOpenModals(!openModals);
                            }}
                          >
                            Disconnect
                          </Button>
                        ) : (
                          <>
                            {btnLoader ? (
                              <>
                                <Button
                                  style={{
                                    // marginTop: 25,
                                    borderRadius: 15,

                                    padding: 10,
                                    fontWeight: "bold",
                                    // marginTop: 10,
                                    width: 140,
                                  }}
                                  variant="success"
                                  disabled
                                >
                                  <Spinner
                                    animation="border"
                                    variant="light"
                                    size="sm"
                                  />
                                </Button>
                              </>
                            ) : (
                              <Button
                                style={{
                                  // marginTop: 25,
                                  borderRadius: 15,
                                  padding: 10,
                                  fontWeight: "bold",
                                  // marginTop: 10,
                                  backgroundColor: "#37a703",
                                }}
                                variant="success"
                                onClick={() => {
                                  mixpanel.track(
                                    `Connect Payment Account (Online Payments Web)`
                                  );
                                  getSrc();
                                  // navigate("/iframe");
                                }}
                              >
                                Connect to Stripe
                              </Button>
                            )}
                          </>
                        )}
                      </div>
                      {data &&
                      data.stripe &&
                      data.stripe.chargesEnabled &&
                      data.paypal &&
                      data.paypal.chargesEnable ? (
                        <>
                          {data.business_default_payment_method === "STRIPE" ? (
                            <div
                              style={{
                                fontSize: 12,
                                textAlign: "center",
                                marginTop: 20,
                                color: "green",
                                fontWeight: "bold",
                              }}
                            >
                              <Image
                                style={{
                                  width: 20,
                                  color: "green",
                                  marginRight: 4,
                                }}
                                tintColor="green"
                                src={check90}
                              />
                              Primary payment methord
                            </div>
                          ) : (
                            <div
                              style={{
                                fontSize: 12,
                                textAlign: "center",
                                marginTop: 20,
                                cursor: "pointer",
                                fontWeight: "bold",
                              }}
                              onClick={async () => {
                                var user = firebase.auth().currentUser;
                                if (user) {
                                  let tokenss = await user.getIdToken();
                                  fetch(
                                    "https://bestmate.us/api/onboard/payments-combined/set-default-method",
                                    {
                                      method: "POST",
                                      headers: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                        Authorization: "Bearer " + tokenss,
                                      },
                                      body: JSON.stringify({
                                        default_payment_method: "STRIPE",
                                      }),
                                    }
                                  )
                                    .then(async (res) => {
                                      if (
                                        res.status >= 200 &&
                                        res.status <= 300
                                      ) {
                                        setData({
                                          ...data,
                                          business_default_payment_method:
                                            "STRIPE",
                                        });
                                      }
                                    })
                                    .catch((err) => console.log(err));
                                }
                              }}
                            >
                              Set as primary payment methord
                            </div>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Row
                    style={{
                      maxWidth: 1100,
                      alignSelf: "center",
                      paddingRight: 10,
                      paddingLeft: 10,
                      backgroundColor: "white",
                      marginTop: 25,
                      marginBottom: 15,
                      display: "flex",

                      boxShadow: "0px 0.5px 4px grey",
                      flexDirection: "row",
                      borderRadius: 20,
                      alignItems: "center",
                      paddingTop: 10,
                      paddingBottom: 10,
                    }}
                  >
                    <Col md={2}>
                      <div
                        style={{
                          width: "100%",
                          // backgroundColor: "pink",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                          // marginTop: 20,
                        }}
                      >
                        <Image style={{ width: 100, margin: 5 }} src={paypal} />
                      </div>
                    </Col>
                    <Col md={4}>
                      <div
                        style={{
                          width: "100%",
                          // backgroundColor: "pink",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          // alignItems: "center",
                          // marginTop: 20,
                        }}
                      >
                        <p
                          style={{
                            fontSize: 18,
                            marginBottom: 5,
                            fontWeight: "bold",
                            color: "#3f80ea",
                          }}
                        >
                          Secure payment options
                        </p>
                        <p>
                          Paypal makes it easy to accept credit cards or
                          transfer from your client's PayPal accounts.
                        </p>
                        <div
                          style={{
                            // fontSize: 14,
                            // fontWeight: "bold",
                            padding: 5,
                            border: 0,
                            display: "flex",
                            flexDirection: "row",
                            // justifyContent: "center",
                            alignItems: "center",
                            // borderBottomWidth: 1,
                            // borderColor: "#d6d6d6",
                            // borderStyle: "dashed",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faCheckSquare}
                            color="#2E6ACF"
                            style={{ marginRight: 10 }}
                          />
                          <div style={{ lineHeight: "120%" }}>
                            Get paid faster anytime anywhere
                          </div>
                        </div>
                        <div
                          style={{
                            // fontSize: 14,
                            // fontWeight: "bold",
                            padding: 5,
                            border: 0,
                            display: "flex",
                            flexDirection: "row",
                            // justifyContent: "center",
                            alignItems: "center",
                            // borderBottomWidth: 1,
                            // borderColor: "#d6d6d6",
                            // borderStyle: "dashed",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faCheckSquare}
                            color="#2E6ACF"
                            style={{ marginRight: 10 }}
                          />
                          <div style={{ lineHeight: "120%" }}>
                            Any mode - Cards, Netbank, Wallet
                          </div>
                        </div>
                        <div
                          style={{
                            // fontSize: 14,
                            // fontWeight: "bold",
                            padding: 5,
                            border: 0,
                            display: "flex",
                            flexDirection: "row",
                            // justifyContent: "center",
                            alignItems: "center",
                            // borderBottomWidth: 1,
                            // borderColor: "#d6d6d6",
                            // borderStyle: "dashed",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faCheckSquare}
                            color="#2E6ACF"
                            style={{ marginRight: 10 }}
                          />
                          <div style={{ lineHeight: "120%" }}>Low Cost</div>
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div style={{ textAlign: "center" }}>
                        <div
                          style={{
                            marginTop: 10,
                            fontSize: 12,
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          CREDIT CARDS
                        </div>
                        <div>
                          <Image style={{ width: 30, margin: 5 }} src={visa} />
                          <Image
                            style={{ width: 30, margin: 5 }}
                            src={master}
                          />
                          <Image
                            style={{ width: 38, margin: 3 }}
                            src={discover}
                          />
                          <Image style={{ width: 55, margin: 0 }} src={amex} />
                        </div>
                        <div
                          style={{
                            marginTop: 10,
                            fontSize: 12,
                            fontWeight: "bold",
                            color: "black",
                            marginBottom: 5,
                          }}
                        >
                          OTHER METHORDS
                        </div>
                        <div>
                          <Image
                            style={{ width: 60, margin: 5 }}
                            src={paypal}
                          />
                          <Image style={{ width: 60, margin: 0 }} src={venmo} />
                        </div>

                        <div
                          style={{
                            color: "#3f80ea",
                            cursor: "pointer",
                            fontSize: 12,
                          }}
                        >
                          Learn about PayPal's Transaction Fees by Country
                        </div>


                      </div>
                    </Col>
                    <Col md={2}>
                      <div
                        style={{
                          width: "100%",
                          // backgroundColor: "pink",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                          // marginTop: 20,
                        }}
                      >
                        {data &&
                        data.paypal &&
                        (data.paypal.hasAccount ||
                          data.paypal.payments_receivable ||
                          data.paypal.isEmailConfirmed) ? (
                          <Button
                            style={{
                              // marginTop: 25,
                              borderRadius: 15,
                              marginLeft: 18,
                              padding: 10,
                              fontWeight: "bold",
                              // marginTop: 10,
                            }}
                            variant="light"
                            onClick={() => {
                              mixpanel.track(
                                `Discount Payment Account (Online Payments Web)`
                              );
                              setOpenModalsPaypal(!openModalsPaypal);
                            }}
                          >
                            Disconnect
                          </Button>
                        ) : (
                          <>
                            {btnLoaderPaypal ? (
                              <>
                                <Button
                                  style={{
                                    // marginTop: 25,
                                    borderRadius: 15,

                                    padding: 10,
                                    fontWeight: "bold",
                                    // marginTop: 10,
                                    width: 140,
                                  }}
                                  disabled
                                  variant="success"
                                >
                                  <Spinner
                                    animation="border"
                                    variant="light"
                                    size="sm"
                                  />
                                </Button>
                              </>
                            ) : (
                              <Button
                                style={{
                                  // marginTop: 25,
                                  borderRadius: 15,
                                  padding: 10,
                                  fontWeight: "bold",
                                  // marginTop: 10,
                                  backgroundColor: "#37a703",
                                }}
                                variant="success"
                                onClick={() => {
                                  mixpanel.track(
                                    `Connect Payment Account (Online Payments Web)`
                                  );
                                  getSrcPaypal();
                                  // navigate("/iframe");
                                }}
                              >
                                Connect to Paypal
                              </Button>
                            )}
                          </>
                        )}
                      </div>
                      {data &&
                      data.paypal &&
                      data.paypal.hasAccount &&
                      data.paypal.payments_receivable &&
                      !data.paypal.isEmailConfirmed ? (
                        // !data.paypal.isEmailConfirmed ? (
                        <div
                          style={{ fontSize: 12, color: "red", marginTop: 10 }}
                        >
                          Disclaimer : Paypal Email needs to be verified to
                          connect account and start accepting payments
                        </div>
                      ) : (
                        <></>
                      )}
                      {data &&
                      data.paypal &&
                      !data.paypal.chargesEnable &&
                      data.paypal.isEmailConfirmed ? (
                        // !data.paypal.isEmailConfirmed ? (
                        <div
                          style={{ fontSize: 12, color: "red", marginTop: 10 }}
                        >
                          Disclaimer : Paypal Onboarding not complete.
                        </div>
                      ) : (
                        <></>
                      )}
                      {data &&
                      data.stripe &&
                      data.stripe.chargesEnabled &&
                      data.paypal &&
                      data.paypal.chargesEnable ? (
                        <>
                          {data.business_default_payment_method === "PAYPAL" ? (
                            <div
                              style={{
                                fontSize: 12,
                                textAlign: "center",
                                marginTop: 20,
                                color: "green",
                                fontWeight: "bold",
                              }}
                            >
                              <Image
                                style={{
                                  width: 20,
                                  color: "green",
                                  marginRight: 4,
                                }}
                                tintColor="green"
                                src={check90}
                              />
                              Primary payment methord
                            </div>
                          ) : (
                            <div
                              style={{
                                fontSize: 12,
                                textAlign: "center",
                                marginTop: 20,
                                cursor: "pointer",
                                fontWeight: "bold",
                              }}
                              onClick={async () => {
                                var user = firebase.auth().currentUser;
                                if (user) {
                                  let tokenss = await user.getIdToken();
                                  fetch(
                                    "https://bestmate.us/api/onboard/payments-combined/set-default-method",
                                    {
                                      method: "POST",
                                      headers: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                        Authorization: "Bearer " + tokenss,
                                      },
                                      body: JSON.stringify({
                                        default_payment_method: "PAYPAL",
                                      }),
                                    }
                                  )
                                    .then(async (res) => {
                                      if (
                                        res.status >= 200 &&
                                        res.status <= 300
                                      ) {
                                        setData({
                                          ...data,
                                          business_default_payment_method:
                                            "PAYPAL",
                                        });
                                      }
                                    })
                                    .catch((err) => console.log(err));
                                }
                              }}
                            >
                              Set as primary payment methord
                            </div>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </div> */}
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Row
                    style={{
                      maxWidth: 1000,
                      alignSelf: "center",
                      paddingRight: 10,
                      paddingLeft: 10,
                      marginTop: 10,
                      marginBottom: 15,
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",

                      flexDirection: "row",
                      // borderRadius: 20,
                      // alignItems: "center",
                      // paddingTop: 10,
                      // backgroundColor: "pink",
                      paddingBottom: 10,
                      borderRadius: 20,
                    }}
                  >
                    <Col md={4}>
                      <div
                        style={{
                          // width: "100%",
                          backgroundColor: "white",
                          display: "flex",
                          // justifyContent: "center",
                          flexDirection: "column",
                          // alignItems: "center",
                          flex: 1,
                          // marginTop: 20,
                          margin: 20,
                          // marginRight: 60,
                          // marginLeft: 50,
                          borderRadius: 10,
                          boxShadow: "0px 0.5px 4px grey",
                          padding: 10,
                          paddingRight: 30,
                          paddingLeft: 20,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            // backgroundColor: "pink",
                          }}
                        >
                          <Image
                            style={{ width: 100, marginRight: 0, margin: 5 }}
                            src={stripe}
                          />
                          {data &&
                          (data.stripe && data.stripe.chargesEnabled ? 1 : 0) +
                            (data.paypal && data.paypal.chargesEnable ? 1 : 0) +
                            (data.square && data.square.chargesEnabled
                              ? 1
                              : 0) >=
                            2 ? (
                            <>
                              {data.business_default_payment_method ===
                              "STRIPE" ? (
                                <div
                                  style={{
                                    backgroundColor: "green",
                                    // height: 20,
                                    alignSelf: "center",
                                    justifyContent: "center",
                                    paddingRight: 5,
                                    paddingLeft: 5,
                                    borderRadius: 5,
                                    maxWidth: 100,
                                    // maxHeight: 30,
                                  }}
                                >
                                  <div
                                    style={{
                                      fontSize: 10,
                                      textAlign: "center",
                                      // marginTop: 20,
                                      color: "white",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Primary payment method
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>

                        <div style={{ marginLeft: 15 }}>
                          <p>
                            Connect or create an account with Stripe to accept
                            credit card payments from clients around the world.
                          </p>
                          <div style={{}}>
                            <div
                              style={{
                                marginTop: 10,
                                fontSize: 12,
                                fontWeight: "bold",
                                color: "black",
                              }}
                            >
                              CREDIT CARDS
                            </div>
                            <div>
                              <Image
                                style={{ width: 30, margin: 5 }}
                                src={visa}
                              />
                              <Image
                                style={{ width: 30, margin: 5 }}
                                src={master}
                              />
                              <Image
                                style={{ width: 50, margin: 0 }}
                                src={apay}
                              />

                              <Image
                                style={{ width: 50, margin: 0 }}
                                src={gpay}
                              />
                            </div>
                            <div
                              style={{
                                fontSize: 13,
                                display: "flex",
                                alignItems: "center",
                                // justifyContent: "center",
                              }}
                            >
                              2.9% + $0.30
                              <span
                                style={{
                                  marginLeft: 3,
                                  fontSize: 10,
                                  color: "grey",
                                }}
                              >
                                per transaction for most cards
                              </span>
                            </div>
                            <div
                              style={{
                                color: "#3f80ea",
                                cursor: "pointer",
                                fontSize: 12,
                              }}
                            >
                              View fee table
                            </div>
                            <div
                              style={{
                                marginTop: 10,
                                fontSize: 12,
                                fontWeight: "bold",
                                color: "black",
                                marginBottom: 5,
                              }}
                            >
                              ACH BANK TRANSFER
                            </div>
                            <div>
                              <Image
                                style={{ width: 40, margin: 0 }}
                                src={ach}
                              />
                            </div>
                            <div
                              style={{
                                fontSize: 13,
                                display: "flex",
                                alignItems: "center",
                                // justifyContent: "center",
                              }}
                            >
                              1%
                              <span
                                style={{
                                  marginLeft: 3,
                                  fontSize: 10,
                                  color: "grey",
                                }}
                              >
                                per transaction
                              </span>
                            </div>
                          </div>

                          <div
                            style={{
                              width: "100%",
                              // backgroundColor: "pink",
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              alignItems: "center",

                              marginTop: 20,
                            }}
                          >
                            {data &&
                            data.stripe &&
                            data.stripe.chargesEnabled ? (
                              <Button
                                style={{
                                  // marginTop: 25,
                                  borderRadius: 15,
                                  // marginLeft: 18,
                                  padding: 10,
                                  fontWeight: "bold",
                                  width: "100%",
                                  // marginTop: 10,
                                }}
                                variant="light"
                                onClick={() => {
                                  mixpanel.track(
                                    `Discount Payment Account (Online Payments Web)`
                                  );
                                  setOpenModals(!openModals);
                                }}
                              >
                                Disconnect Stripe
                              </Button>
                            ) : (
                              <>
                                {btnLoader ? (
                                  <>
                                    <Button
                                      style={{
                                        // marginTop: 25,
                                        borderRadius: 15,

                                        padding: 10,
                                        fontWeight: "bold",
                                        // marginTop: 10,
                                        // width: 140,
                                        width: "100%",
                                      }}
                                      variant="success"
                                      disabled
                                    >
                                      <Spinner
                                        animation="border"
                                        variant="light"
                                        size="sm"
                                      />
                                    </Button>
                                  </>
                                ) : (
                                  <Button
                                    style={{
                                      // marginTop: 25,
                                      borderRadius: 15,
                                      padding: 10,
                                      fontWeight: "bold",
                                      width: "100%",
                                      // marginTop: 10,
                                      backgroundColor: "#37a703",
                                    }}
                                    variant="success"
                                    onClick={() => {
                                      mixpanel.track(
                                        `Connect Payment Account (Online Payments Web)`
                                      );
                                      getSrc();
                                      // navigate("/iframe");
                                    }}
                                  >
                                    Connect to Stripe
                                  </Button>
                                )}
                              </>
                            )}
                          </div>
                          {data &&
                          (data.stripe && data.stripe.chargesEnabled ? 1 : 0) +
                            (data.paypal && data.paypal.chargesEnable ? 1 : 0) +
                            (data.square && data.square.chargesEnabled
                              ? 1
                              : 0) >=
                            2 ? (
                            <>
                              {data.stripe &&
                              data.stripe.chargesEnabled &&
                              data.business_default_payment_method !==
                                "STRIPE" ? (
                                <div
                                  style={{
                                    fontSize: 12,
                                    textAlign: "center",
                                    marginTop: 20,
                                    cursor: "pointer",
                                    fontWeight: "bold",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div>Set as primary payment method</div>
                                  <Form>
                                    <Form.Check // prettier-ignore
                                      type="switch"
                                      id="custom-switch"
                                      onChange={async (val) => {
                                        var user = firebase.auth().currentUser;
                                        if (user) {
                                          let tokenss = await user.getIdToken();
                                          fetch(
                                            "https://bestmate.us/api/onboard/payments-combined/set-default-method",
                                            {
                                              method: "POST",
                                              headers: {
                                                Accept: "application/json",
                                                "Content-Type":
                                                  "application/json",
                                                Authorization:
                                                  "Bearer " + tokenss,
                                              },
                                              body: JSON.stringify({
                                                default_payment_method:
                                                  "STRIPE",
                                              }),
                                            }
                                          )
                                            .then(async (res) => {
                                              if (
                                                res.status >= 200 &&
                                                res.status <= 300
                                              ) {
                                                setData({
                                                  ...data,
                                                  business_default_payment_method:
                                                    "STRIPE",
                                                });
                                              }
                                            })
                                            .catch((err) => console.log(err));
                                        }
                                      }}
                                    />
                                  </Form>
                                </div>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                          {data &&
                          (data.stripe && data.stripe.chargesEnabled ? 1 : 0) +
                            (data.paypal && data.paypal.chargesEnable ? 1 : 0) +
                            (data.square && data.square.chargesEnabled
                              ? 1
                              : 0) >=
                            2 ? (
                            <>
                              {data.business_default_payment_method !==
                              "STRIPE" ? (
                                <></>
                              ) : (
                                <div
                                  style={{
                                    height: 55,
                                    // backgroundColor: "pink",
                                  }}
                                ></div>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </Col>
                    {(data &&
                      data.paypal &&
                      (data.paypal.hasAccount ||
                        data.paypal.payments_receivable ||
                        data.paypal.isEmailConfirmed)) ||
                    [
                      "AUD",
                      "BRL",
                      "CAD",
                      "CNY",
                      "CZK",
                      "DKK",
                      "EUR",
                      "HKD",
                      "HUF",
                      "ILS",
                      "JPY",
                      "MYR",
                      "MXN",
                      "TWD",
                      "NZD",
                      "NOK",
                      "PHP",
                      "PLN",
                      "GBP",
                      "SGD",
                      "SEK",
                      "CHF",
                      "THB",
                      "USD",
                    ].includes(usercurrency) ? (
                      <Col md={4}>
                        <div
                          style={{
                            // width: "100%",
                            backgroundColor: "white",
                            display: "flex",
                            // justifyContent: "center",
                            flexDirection: "column",
                            // alignItems: "center",
                            flex: 1,
                            // marginTop: 20,
                            margin: 20,
                            // marginRight: 50,
                            // marginLeft: 60,
                            borderRadius: 10,
                            boxShadow: "0px 0.5px 4px grey",
                            padding: 10,
                            paddingRight: 30,
                            paddingLeft: 20,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              marginBottom: 7,
                              // backgroundColor: "pink",
                            }}
                          >
                            <Image
                              style={{
                                width: 110,
                                marginRight: 0,
                                margin: 10,
                                marginLeft: 10,
                              }}
                              src={paypal}
                            />

                            {data &&
                            (data.stripe && data.stripe.chargesEnabled
                              ? 1
                              : 0) +
                              (data.paypal && data.paypal.chargesEnable
                                ? 1
                                : 0) +
                              (data.square && data.square.chargesEnabled
                                ? 1
                                : 0) >=
                              2 ? (
                              <>
                                {data.business_default_payment_method ===
                                "PAYPAL" ? (
                                  <div
                                    style={{
                                      backgroundColor: "green",
                                      // height: 20,
                                      alignSelf: "center",
                                      justifyContent: "center",
                                      paddingRight: 5,
                                      paddingLeft: 5,
                                      borderRadius: 5,
                                      maxWidth: 100,
                                      // maxHeight: 30,
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: 10,
                                        textAlign: "center",
                                        // marginTop: 20,
                                        color: "white",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Primary payment method
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>

                          <div style={{ marginLeft: 15 }}>
                            <p>
                              PayPal makes it easy to accept credit cards or
                              transfer from your client's PayPal accounts, and
                              charges less per transaction.
                            </p>
                            <div style={{}}>
                              <div
                                style={{
                                  marginTop: 10,
                                  fontSize: 12,
                                  fontWeight: "bold",
                                  color: "black",
                                }}
                              >
                                CREDIT CARDS
                              </div>
                              <div>
                                <Image
                                  style={{ width: 30, margin: 5 }}
                                  src={visa}
                                />
                                <Image
                                  style={{ width: 30, margin: 5 }}
                                  src={master}
                                />
                                <Image
                                  style={{ width: 38, margin: 3 }}
                                  src={discover}
                                />
                                <Image
                                  style={{ width: 55, margin: 0 }}
                                  src={amex}
                                />
                              </div>
                              <div
                                style={{
                                  marginTop: 10,
                                  fontSize: 12,
                                  fontWeight: "bold",
                                  color: "black",
                                  marginBottom: 5,
                                }}
                              >
                                OTHER METHODS
                              </div>
                              <div>
                                <Image
                                  style={{ width: 60, margin: 5 }}
                                  src={paypal}
                                />
                                <Image
                                  style={{ width: 60, margin: 0 }}
                                  src={venmo}
                                />
                              </div>

                              <div
                                style={{
                                  color: "#3f80ea",
                                  cursor: "pointer",
                                  fontSize: 12,
                                }}
                              >
                                Learn about PayPal's Transaction Fees by Country
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                // backgroundColor: "pink",
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                                marginTop: 28,
                              }}
                            >
                              {data &&
                              data.paypal &&
                              (data.paypal.hasAccount ||
                                data.paypal.payments_receivable ||
                                data.paypal.isEmailConfirmed) ? (
                                <>
                                  <Button
                                    style={{
                                      // marginTop: 25,
                                      borderRadius: 15,
                                      // marginLeft: 18,
                                      width: "100%",
                                      padding: 10,
                                      fontWeight: "bold",
                                      // marginTop: 10,
                                    }}
                                    variant="light"
                                    onClick={() => {
                                      mixpanel.track(
                                        `Discount Payment Account (Online Payments Web)`
                                      );
                                      setOpenModalsPaypal(!openModalsPaypal);
                                    }}
                                  >
                                    Disconnect PayPal
                                  </Button>
                                </>
                              ) : (
                                <>
                                  {btnLoaderPaypal ? (
                                    <>
                                      <Button
                                        style={{
                                          // marginTop: 25,
                                          borderRadius: 15,
                                          width: "100%",
                                          padding: 10,
                                          fontWeight: "bold",
                                          // marginTop: 10,
                                          width: "100%",
                                        }}
                                        disabled
                                        variant="success"
                                      >
                                        <Spinner
                                          animation="border"
                                          variant="light"
                                          size="sm"
                                        />
                                      </Button>
                                    </>
                                  ) : (
                                    <Button
                                      style={{
                                        // marginTop: 25,
                                        borderRadius: 15,
                                        padding: 10,
                                        fontWeight: "bold",
                                        width: "100%",
                                        // marginTop: 10,
                                        backgroundColor: "#37a703",
                                      }}
                                      variant="success"
                                      onClick={() => {
                                        mixpanel.track(
                                          `Connect Payment Account (Online Payments Web)`
                                        );
                                        getSrcPaypal();
                                        // navigate("/iframe");
                                      }}
                                    >
                                      Connect to PayPal
                                    </Button>
                                  )}
                                </>
                              )}
                            </div>

                            {data &&
                            (data.stripe && data.stripe.chargesEnabled
                              ? 1
                              : 0) +
                              (data.paypal && data.paypal.chargesEnable
                                ? 1
                                : 0) +
                              (data.square && data.square.chargesEnabled
                                ? 1
                                : 0) >=
                              2 ? (
                              <>
                                {data.business_default_payment_method !==
                                "PAYPAL" ? (
                                  <></>
                                ) : (
                                  <div
                                    style={{
                                      height: 57,
                                      // backgroundColor: "pink",
                                    }}
                                  ></div>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                            {data &&
                            data.paypal &&
                            data.paypal.hasAccount &&
                            data.paypal.payments_receivable &&
                            !data.paypal.isEmailConfirmed ? (
                              // {/* {true ? ( */}
                              <div
                                style={{
                                  fontSize: 12,
                                  color: "red",
                                  marginTop: 10,
                                  // maxWidth: "100%",
                                  // display: "flex",
                                  overflowWrap: "break-word",
                                  // backgroundColor: "pink",
                                }}
                              >
                                Attention: Please confirm your email address on{" "}
                                <a
                                  style={{
                                    overflowWrap: "break-word",
                                    display: "inline",
                                  }}
                                  href=" https://www.paypal.com/businessprofile/settings"
                                >
                                  https://www.paypal.com/businessprofile/settings
                                </a>{" "}
                                in order to receive payments! You currently
                                cannot receive payments.
                              </div>
                            ) : (
                              <>
                                {data &&
                                data.paypal &&
                                data.paypal.hasAccount &&
                                !data.paypal.payments_receivable ? (
                                  <div
                                    style={{
                                      fontSize: 12,
                                      color: "red",
                                      marginTop: 10,
                                    }}
                                  >
                                    Attention: You currently cannot receive
                                    payments due to restriction on your PayPal
                                    account. Please reach out to PayPal Customer
                                    Support or connect to{" "}
                                    <a href="https://www.paypal.com/businessprofile/settings">
                                      https://www.paypal.com
                                    </a>{" "}
                                    for more information.
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </>
                            )}

                            {data &&
                            (data.stripe && data.stripe.chargesEnabled
                              ? 1
                              : 0) +
                              (data.paypal && data.paypal.chargesEnable
                                ? 1
                                : 0) +
                              (data.square && data.square.chargesEnabled
                                ? 1
                                : 0) >=
                              2 ? (
                              <>
                                {data &&
                                data.paypal &&
                                (data.paypal.hasAccount ||
                                  data.paypal.payments_receivable ||
                                  data.paypal.isEmailConfirmed) &&
                                data.business_default_payment_method !==
                                  "PAYPAL" ? (
                                  <div
                                    style={{
                                      fontSize: 12,
                                      textAlign: "center",
                                      marginTop: 20,
                                      cursor: "pointer",
                                      fontWeight: "bold",
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div>Set as primary payment method</div>
                                    <Form>
                                      <Form.Check // prettier-ignore
                                        type="switch"
                                        id="custom-switch"
                                        onChange={async (val) => {
                                          console.log(val.target.checked);
                                          if (val.target.checked) {
                                            var user =
                                              firebase.auth().currentUser;
                                            if (user) {
                                              let tokenss =
                                                await user.getIdToken();
                                              fetch(
                                                "https://bestmate.us/api/onboard/payments-combined/set-default-method",
                                                {
                                                  method: "POST",
                                                  headers: {
                                                    Accept: "application/json",
                                                    "Content-Type":
                                                      "application/json",
                                                    Authorization:
                                                      "Bearer " + tokenss,
                                                  },
                                                  body: JSON.stringify({
                                                    default_payment_method:
                                                      "PAYPAL",
                                                  }),
                                                }
                                              )
                                                .then(async (res) => {
                                                  if (
                                                    res.status >= 200 &&
                                                    res.status <= 300
                                                  ) {
                                                    setData({
                                                      ...data,
                                                      business_default_payment_method:
                                                        "PAYPAL",
                                                    });
                                                  }
                                                })
                                                .catch((err) =>
                                                  console.log(err)
                                                );
                                            }
                                          }
                                        }}
                                      />
                                    </Form>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </Col>
                    ) : (
                      <></>
                    )}
                    {bdata.business_email === "demosquarett@gmail.com" ? (
                      <Col md={4}>
                        <div
                          style={{
                            // width: "100%",
                            backgroundColor: "white",
                            display: "flex",
                            // justifyContent: "center",
                            flexDirection: "column",
                            // alignItems: "center",
                            flex: 1,
                            // marginTop: 20,
                            margin: 20,
                            // marginRight: 60,
                            // marginLeft: 50,
                            borderRadius: 10,
                            boxShadow: "0px 0.5px 4px grey",
                            padding: 10,
                            paddingRight: 30,
                            paddingLeft: 20,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              // backgroundColor: "pink",
                            }}
                          >
                            <Image
                              style={{
                                width: 100,
                                marginRight: 0,
                                margin: 5,
                                paddingTop: 5,
                                paddingBottom: 5,
                              }}
                              src={square}
                            />
                            {data &&
                            (data.stripe && data.stripe.chargesEnabled
                              ? 1
                              : 0) +
                              (data.paypal && data.paypal.chargesEnable
                                ? 1
                                : 0) +
                              (data.square && data.square.chargesEnabled
                                ? 1
                                : 0) >=
                              2 ? (
                              <>
                                {data.business_default_payment_method ===
                                "SQUARE" ? (
                                  <div
                                    style={{
                                      backgroundColor: "green",
                                      // height: 20,
                                      alignSelf: "center",
                                      justifyContent: "center",
                                      paddingRight: 5,
                                      paddingLeft: 5,
                                      borderRadius: 5,
                                      maxWidth: 100,
                                      // maxHeight: 30,
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: 10,
                                        textAlign: "center",
                                        // marginTop: 20,
                                        color: "white",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Primary payment method
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>

                          <div style={{ marginLeft: 15 }}>
                            <p>
                              Connect or create an account with Square to accept
                              credit card payments from clients around the
                              world.
                            </p>
                            <div style={{}}>
                              <div
                                style={{
                                  marginTop: 10,
                                  fontSize: 12,
                                  fontWeight: "bold",
                                  color: "black",
                                }}
                              >
                                CREDIT CARDS
                              </div>
                              <div>
                                <Image
                                  style={{ width: 30, margin: 5 }}
                                  src={visa}
                                />
                                <Image
                                  style={{ width: 30, margin: 5 }}
                                  src={master}
                                />
                                <Image
                                  style={{ width: 50, margin: 0 }}
                                  src={apay}
                                />

                                <Image
                                  style={{ width: 50, margin: 0 }}
                                  src={gpay}
                                />
                              </div>
                              <div
                                style={{
                                  fontSize: 13,
                                  display: "flex",
                                  alignItems: "center",
                                  // justifyContent: "center",
                                }}
                              >
                                2.9% + $0.30
                                <span
                                  style={{
                                    marginLeft: 3,
                                    fontSize: 10,
                                    color: "grey",
                                  }}
                                >
                                  per transaction for most cards
                                </span>
                              </div>
                              <div
                                style={{
                                  color: "#3f80ea",
                                  cursor: "pointer",
                                  fontSize: 12,
                                }}
                              >
                                View fee table
                              </div>
                              <div
                                style={{
                                  marginTop: 10,
                                  fontSize: 12,
                                  fontWeight: "bold",
                                  color: "black",
                                  marginBottom: 5,
                                }}
                              >
                                ACH BANK TRANSFER
                              </div>
                              <div>
                                <Image
                                  style={{ width: 40, margin: 0 }}
                                  src={ach}
                                />
                              </div>
                              <div
                                style={{
                                  fontSize: 13,
                                  display: "flex",
                                  alignItems: "center",
                                  // justifyContent: "center",
                                }}
                              >
                                1%
                                <span
                                  style={{
                                    marginLeft: 3,
                                    fontSize: 10,
                                    color: "grey",
                                  }}
                                >
                                  per transaction
                                </span>
                              </div>
                            </div>

                            <div
                              style={{
                                width: "100%",
                                // backgroundColor: "pink",
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",

                                marginTop: 20,
                              }}
                            >
                              {data &&
                              data.square &&
                              data.square.chargesEnabled ? (
                                <Button
                                  style={{
                                    // marginTop: 25,
                                    borderRadius: 15,
                                    // marginLeft: 18,
                                    padding: 10,
                                    fontWeight: "bold",
                                    width: "100%",
                                    // marginTop: 10,
                                  }}
                                  variant="light"
                                  onClick={() => {
                                    mixpanel.track(
                                      `Discount Payment Account (Online Payments Web)`
                                    );
                                    setOpenModalsSquare(!openModalsSquare);
                                  }}
                                >
                                  Disconnect Square
                                </Button>
                              ) : (
                                <>
                                  {squareBtnLoader ? (
                                    <>
                                      <Button
                                        style={{
                                          // marginTop: 25,
                                          borderRadius: 15,

                                          padding: 10,
                                          fontWeight: "bold",
                                          // marginTop: 10,
                                          // width: 140,
                                          width: "100%",
                                        }}
                                        variant="success"
                                        disabled
                                      >
                                        <Spinner
                                          animation="border"
                                          variant="light"
                                          size="sm"
                                        />
                                      </Button>
                                    </>
                                  ) : (
                                    <Button
                                      style={{
                                        // marginTop: 25,
                                        borderRadius: 15,
                                        padding: 10,
                                        fontWeight: "bold",
                                        width: "100%",
                                        // marginTop: 10,
                                        backgroundColor: "#37a703",
                                      }}
                                      variant="success"
                                      onClick={() => {
                                        mixpanel.track(
                                          `Connect Payment Account (Online Payments Web)`
                                        );
                                        getSquareSrc();
                                        // navigate("/iframe");
                                      }}
                                    >
                                      Connect to Square
                                    </Button>
                                  )}
                                </>
                              )}
                            </div>
                            {data &&
                            (data.stripe && data.stripe.chargesEnabled
                              ? 1
                              : 0) +
                              (data.paypal && data.paypal.chargesEnable
                                ? 1
                                : 0) +
                              (data.square && data.square.chargesEnabled
                                ? 1
                                : 0) >=
                              2 ? (
                              <>
                                {data.square &&
                                data.square.chargesEnabled &&
                                data.business_default_payment_method !==
                                  "SQUARE" ? (
                                  <div
                                    style={{
                                      fontSize: 12,
                                      textAlign: "center",
                                      marginTop: 20,
                                      cursor: "pointer",
                                      fontWeight: "bold",
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div>Set as primary payment method</div>
                                    <Form>
                                      <Form.Check // prettier-ignore
                                        type="switch"
                                        id="custom-switch"
                                        onChange={async (val) => {
                                          var user =
                                            firebase.auth().currentUser;
                                          if (user) {
                                            let tokenss =
                                              await user.getIdToken();
                                            fetch(
                                              "https://bestmate.us/api/onboard/payments-combined/set-default-method",
                                              {
                                                method: "POST",
                                                headers: {
                                                  Accept: "application/json",
                                                  "Content-Type":
                                                    "application/json",
                                                  Authorization:
                                                    "Bearer " + tokenss,
                                                },
                                                body: JSON.stringify({
                                                  default_payment_method:
                                                    "SQUARE",
                                                }),
                                              }
                                            )
                                              .then(async (res) => {
                                                if (
                                                  res.status >= 200 &&
                                                  res.status <= 300
                                                ) {
                                                  setData({
                                                    ...data,
                                                    business_default_payment_method:
                                                      "SQUARE",
                                                  });
                                                }
                                              })
                                              .catch((err) => console.log(err));
                                          }
                                        }}
                                      />
                                    </Form>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                            {data &&
                            (data.stripe && data.stripe.chargesEnabled
                              ? 1
                              : 0) +
                              (data.paypal && data.paypal.chargesEnable
                                ? 1
                                : 0) +
                              (data.square && data.square.chargesEnabled
                                ? 1
                                : 0) >=
                              2 ? (
                              <>
                                {data.business_default_payment_method !==
                                "SQUARE" ? (
                                  <></>
                                ) : (
                                  <div
                                    style={{
                                      height: 55,
                                      // backgroundColor: "pink",
                                    }}
                                  ></div>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </Col>
                    ) : (
                      <></>
                    )}
                  </Row>
                </div>
              </div>
            </div>
          </Container>

          <Modal
            show={openModals}
            onHide={() => setOpenModals(!openModals)}
            centered
            size="sm"
          >
            <Modal.Body style={{ padding: 0 }}>
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#f4f4f4",
                  height: 46,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "16px 24px",
                }}
              >
                <h1
                  style={{
                    fontWeight: "bold",
                    fontSize: 18,
                    color: "black",
                    margin: 0,
                  }}
                >
                  Remove Stripe
                </h1>
                <div
                  onClick={() => {
                    setOpenModals(!openModals);
                  }}
                >
                  <X
                    size={24}
                    strokeWidth={2}
                    stroke="black"
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
              <div style={{ padding: "10px 24px 0 24px" }}>
                <div
                  style={{
                    display: "flex",
                    // justifyContent: "center",
                    // flexDirection: "column",
                    // alignItems: "center",
                  }}
                >
                  <p style={{ margin: "15px 0px 20px", textAlign: "center" }}>
                    Are you sure you want to remove your account?
                  </p>
                </div>

                <hr style={{ margin: 0 }} />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    margin: "12px 0",
                  }}
                >
                  <button
                    type="button"
                    style={{
                      marginRight: 5,
                      padding: "5px 10px",
                      backgroundColor: "white",
                      borderWidth: 1,
                    }}
                    onClick={() => setOpenModals(!openModals)}
                  >
                    Cancel
                  </button>
                  <button
                    style={{
                      padding: "5px 10px",
                      backgroundColor: "#3679e8",
                      borderWidth: 0,
                      color: "white",
                      width: 80,
                    }}
                    // disabled={true}
                    disabled={disconnectbtnLoader}
                    onClick={() => {
                      DisconnectAccount();
                    }}
                  >
                    <>
                      {disconnectbtnLoader ? (
                        <>
                          <Spinner
                            animation="border"
                            variant="light"
                            size="sm"
                          />
                        </>
                      ) : (
                        "Yes"
                      )}
                    </>
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            show={openModalsSquare}
            onHide={() => setOpenModalsSquare(!openModalsSquare)}
            centered
            size="sm"
          >
            <Modal.Body style={{ padding: 0 }}>
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#f4f4f4",
                  height: 46,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "16px 24px",
                }}
              >
                <h1
                  style={{
                    fontWeight: "bold",
                    fontSize: 18,
                    color: "black",
                    margin: 0,
                  }}
                >
                  Remove Square
                </h1>
                <div
                  onClick={() => {
                    setOpenModalsSquare(!openModalsSquare);
                  }}
                >
                  <X
                    size={24}
                    strokeWidth={2}
                    stroke="black"
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
              <div style={{ padding: "10px 24px 0 24px" }}>
                <div
                  style={{
                    display: "flex",
                    // justifyContent: "center",
                    // flexDirection: "column",
                    // alignItems: "center",
                  }}
                >
                  <p style={{ margin: "15px 0px 20px", textAlign: "center" }}>
                    Are you sure you want to remove your account?
                  </p>
                </div>

                <hr style={{ margin: 0 }} />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    margin: "12px 0",
                  }}
                >
                  <button
                    type="button"
                    style={{
                      marginRight: 5,
                      padding: "5px 10px",
                      backgroundColor: "white",
                      borderWidth: 1,
                    }}
                    onClick={() => setOpenModalsSquare(!openModalsSquare)}
                  >
                    Cancel
                  </button>
                  <button
                    style={{
                      padding: "5px 10px",
                      backgroundColor: "#3679e8",
                      borderWidth: 0,
                      color: "white",
                      width: 80,
                    }}
                    // disabled={true}
                    disabled={disconnectbtnLoaderSquare}
                    onClick={() => {
                      DisconnectAccountSquare();
                    }}
                  >
                    <>
                      {disconnectbtnLoaderSquare ? (
                        <>
                          <Spinner
                            animation="border"
                            variant="light"
                            size="sm"
                          />
                        </>
                      ) : (
                        "Yes"
                      )}
                    </>
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            show={openModalsPaypal}
            onHide={() => setOpenModalsPaypal(!openModalsPaypal)}
            centered
            size="sm"
          >
            <Modal.Body style={{ padding: 0 }}>
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#f4f4f4",
                  height: 46,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "16px 24px",
                }}
              >
                <h1
                  style={{
                    fontWeight: "bold",
                    fontSize: 18,
                    color: "black",
                    margin: 0,
                  }}
                >
                  Remove PayPal
                </h1>
                <div
                  onClick={() => {
                    setOpenModalsPaypal(!openModalsPaypal);
                  }}
                >
                  <X
                    size={24}
                    strokeWidth={2}
                    stroke="black"
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
              <div style={{ padding: "10px 24px 0 24px" }}>
                <p
                  style={{
                    // fontSize: 10,
                    // color: "black",
                    marginTop: 10,
                  }}
                >
                  Disclaimer: Disconnecting your PayPal account will prevent you
                  from offering PayPal services and products on your website. Do
                  you wish to continue?
                </p>

                <hr style={{ margin: 0 }} />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    margin: "12px 0",
                  }}
                >
                  <button
                    type="button"
                    style={{
                      marginRight: 5,
                      padding: "5px 10px",
                      backgroundColor: "white",
                      borderWidth: 1,
                    }}
                    onClick={() => setOpenModalsPaypal(!openModalsPaypal)}
                  >
                    Cancel
                  </button>
                  <button
                    style={{
                      padding: "5px 10px",
                      backgroundColor: "#3679e8",
                      borderWidth: 0,
                      color: "white",
                      width: 80,
                    }}
                    // disabled={true}
                    disabled={disconnectbtnLoaderPaypal}
                    onClick={() => {
                      DisconnectAccountPaypal();
                    }}
                  >
                    <>
                      {disconnectbtnLoaderPaypal ? (
                        <>
                          <Spinner
                            animation="border"
                            variant="light"
                            size="sm"
                          />
                        </>
                      ) : (
                        "Yes"
                      )}
                    </>
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </React.Fragment>
      )}
    </>
  );
};

export default OnlinePayments;
